import {classNames} from "../../../../../common/util/util-helpers";
import {TruckIcon, UserIcon, UsersIcon} from "@heroicons/react/20/solid";
import {TrailerSolidIcon} from "../../../../../data/themes/icons";
import React from "react";

export default function StopHistoryItem({driver, coDriver, truck, trailer, trailerPicked, trailerDropped, isLastInSection, bgClass, title, icon}) {
    return (
        <li className={classNames("px-2 pt-4 pm-4 cursor-default", bgClass)}>
            <div className={
                classNames(
                    "flex items-center pb-4 relative",
                    isLastInSection ? "border-b border-tm-gray-300" : undefined
                )
            }>
                <div className="">
                    {!isLastInSection && (
                        <span
                            className="absolute top-5 left-5 -ml-px h-[calc(100%+1.5rem)] w-0.5 bg-tm-gray-200"
                            aria-hidden="true"/>
                    )}
                    <div className="relative flex items-start space-x-3">
                        <div
                            className={
                                classNames(
                                    "relative -top-2 h-10 w-10 rounded-full bg-inverse flex items-center justify-center ring-8 ring-inverse"
                                )
                            }
                        >
                            {icon}
                        </div>
                        <div className="flex-1">
                            <div className="text-sm">
                                <div
                                    className="text-sm text-tm-gray-800 font-bold text-break">{title}</div>
                                {/*<div*/}
                                {/*    className="text-sm text-tm-gray-800 text-break whitespace-pre-wrap">Test 2</div>*/}

                                <div className="pl-1 mt-2 space-y-2">
                                    {!!driver && (
                                        <div
                                            className="text-xs text-tm-gray-600 flex">
                                            <UserIcon className="w-5 h-5 mr-2 text-tm-gray-400"/>
                                            {driver}
                                        </div>
                                    )}

                                    {!!coDriver && (
                                        <div
                                            className="text-xs text-tm-gray-600 flex">
                                            <UsersIcon className="w-5 h-5 mr-2 text-tm-gray-400"/>
                                            {coDriver}
                                        </div>
                                    )}

                                    {!!truck && (
                                        <div
                                            className="text-xs text-tm-gray-500 flex items-center"
                                        >
                                            <TruckIcon className="w-5 h-5 mr-2 text-tm-gray-400"/>
                                            {truck}
                                        </div>
                                    )}

                                    {!!trailer && (
                                        <div
                                            className="text-xs text-tm-gray-500 flex items-center"
                                        >
                                            <TrailerSolidIcon className="w-5 h-5 mr-2 text-tm-gray-400"/>
                                            {trailer}
                                        </div>
                                    )}

                                    {!!trailerDropped && (
                                        <div
                                            className="text-xs text-tm-gray-500 flex items-center"
                                        >
                                            <TrailerSolidIcon className="w-5 h-5 mr-2 text-yellow-600"/>
                                            {trailerDropped}
                                        </div>
                                    )}

                                    {!!trailerPicked && (
                                        <div
                                            className="text-xs text-tm-gray-500 flex items-center"
                                        >
                                            <TrailerSolidIcon className="w-5 h-5 mr-2 text-green-600"/>
                                            {trailerPicked}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}